import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import InfoTag from "@/inline-assets/info-tag.svg";

const Container = styled("div")`
  position: absolute;
  top: var(--sp-5e);
  left: var(--sp-5e);

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--shade0-75);
  background: var(--shade10-75);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  padding: var(--sp-2_5e);
  width: max-content;

  .info-tag {
    fill: var(--shade0-75);
    width: var(--sp-4e);
    margin-right: var(--sp-1_5e);
  }

  visibility: hidden;
  @media (max-aspect-ratio: 16/9) {
    visibility: ${({ $letterbox, $fullscreen }) =>
      !$letterbox || $fullscreen ? "visible" : "hidden"};
  }

  @media (aspect-ratio: 16/9) {
    visibility: hidden;
  }
`;

const UnsupportedOverlayInfo = ({ letterbox, fullscreen }) => {
  const { t } = useTranslation();

  return (
    <Container $letterbox={letterbox} $fullscreen={fullscreen}>
      <InfoTag className="info-tag" />
      <p className="type-caption--bold">
        {t(
          "val:overlay.unsupportedOverlay",
          "This overlay is not currently supported for your Video settings",
        )}
      </p>
    </Container>
  );
};

export default UnsupportedOverlayInfo;

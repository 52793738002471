import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { Outcome } from "@/game-val/CommonComponent.jsx";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { Rank } from "@/game-val/OverlayPlayerRank.jsx";
import {
  calcWinrate,
  getOutcomeShort,
  getOutcomeShortColor,
  kdaColor,
} from "@/game-val/utils.mjs";
import { classNames } from "@/util/class-names.mjs";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div", React.forwardRef)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: var(--shade9-50);
  padding: var(--sp-1e) var(--sp-3e);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  backdrop-filter: blur(var(--sp-2e));
  width: fit-content;
  margin-bottom: var(--sp-14e);
  height: var(--sp-13e);

  &:last-child {
    margin-bottom: 0;
  }

  .column.player-message {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    backdrop-filter: blur(var(--sp-2));
    background-color: var(--shade10-50);
    padding-left: var(--sp-3e);
    border-radius: var(--br);
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .outcome-list {
    margin: var(--sp-1e) 0;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    margin-right: var(--sp-4e);
  }

  div.column:last-child {
    margin-right: 0;
  }

  p.stat-major {
    font-weight: 700;
    font-size: var(--sp-3_5e);
  }

  p.stat-minor {
    font-size: var(--sp-3e);
    color: var(--shade0-75);
  }

  p.numbering {
    position: absolute;
    left: var(--sp-1_5e);
    top: var(--sp-0_5e);
  }
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AgentSelectPlayerStats = ({
  wins = 0,
  matches = 0,
  headshots = 0,
  bodyshots = 0,
  legshots = 0,
  kills = 0,
  deaths = 0,
  assists = 0,
  damage = 0,
  score = 0,
  roundsPlayed = 0,
  matchHistory = [
    { won: true },
    { won: true },
    { won: true },
    { won: true },
    { won: true },
  ],
  rank = 0,
  index,
  isReplication,
  isPrivate = false,
  isRankVisible = false,
}) => {
  const { t } = useTranslation();
  const overlayId = `valAgentSelectP${index + 1}`;

  const state = useSnapshot(readState);
  const valConstants = state.val?.content;
  const ranks = valConstants?.ranks;
  const rankInfo = ranks?.find((r) => r.position === rank);
  const winRate = calcWinrate({ wins, matches });
  const winRateString = displayRate(winRate, 100);
  const kda = calcRate(kills + assists, deaths, 1);
  const kdaString = kda.toLocaleString(getLocale(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const avgScore = calcRate(score, roundsPlayed);
  const avgScoreString = avgScore.toLocaleString(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const adr = calcRate(damage, matches);
  const adrString = adr.toLocaleString(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedHSPercent = displayRate(
    headshots,
    headshots + bodyshots + legshots,
    0,
    1,
  );

  if (!OVERLAY_IDS[overlayId]) return null;

  return (
    <Container
      className={
        classNames(matches === 0 || isPrivate ? "no-data" : "").className
      }
      $index={index}
      $isReplication={isReplication}
      $matches={matches}
    >
      {isPrivate || matches === 0 ? (
        <div className="column player-message">
          <Rank rankInfo={rankInfo} isRankVisible={isRankVisible} />
          <p className="stat-minor">
            {isPrivate
              ? t("val:overlay.privatePlayer", "Player set to private.")
              : t("val:overlay.noMatches", "No matches found for this player.")}
          </p>
        </div>
      ) : null}
      <Rank rankInfo={rankInfo} isRankVisible={isRankVisible} />
      <div className="column">
        <p className="stat-major">
          {winRateString} {t("lol:winRate", "Win Rate")}
        </p>
        <Row className="outcome-list">
          {matchHistory.map(({ won }, index) => {
            const outcome = won ? "win" : "loss";
            return (
              <Outcome className={outcome} key={index}>
                <span
                  className="type-mini"
                  style={{
                    color: getOutcomeShortColor(outcome),
                  }}
                >
                  {getOutcomeShort(t, outcome)}
                </span>
              </Outcome>
            );
          })}
        </Row>
      </div>
      <div className="column">
        <p className="stat-major" style={{ color: kdaColor(kda) }}>
          {t("val:stats.kda", "{{kda}} KDA", {
            kda: kdaString,
          })}
        </p>
        <p className="stat-minor">
          {t("val:stats.adr", "{{adr}} ADR", {
            adr: adrString,
          })}
        </p>
      </div>
      <div className="column">
        <p className="stat-major">
          {t("val:stats.hsPercent", "{{hsPercent}} HS", {
            hsPercent: formattedHSPercent,
          })}
        </p>
        <p className="stat-minor">
          {t("val:stats.avgScores", "{{avgScore}} Avg. Score", {
            avgScore: avgScoreString,
          })}
        </p>
      </div>
      <p className="type-mini numbering">
        {(index + 1).toLocaleString(getLocale())}
      </p>
    </Container>
  );
};

export default AgentSelectPlayerStats;

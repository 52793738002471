import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import { EVENTS, handleMessage } from "@/__main__/ipc-core.mjs";
import Logo from "@/app/Logo.jsx";
import { getModifier } from "@/app/util.mjs";
import SelectedAgent from "@/game-val/SelectedAgent.jsx";
import {
  getAgentImage,
  getWinRateColor,
  kdaColor,
  scoreColorStyle,
} from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import Warning from "@/inline-assets/Warning.svg";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import orderBy from "@/util/order-array-by.mjs";
import useKeypress from "@/util/use-key-press.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  position: fixed;
  &.letterbox {
    position: absolute;
  }
  top: calc(var(--sp-5e));
  right: calc(var(--sp-5e));

  display: flex;
  flex-direction: column;
  width: calc(var(--sp-8e) * 8);
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));

  .filters {
    padding-top: var(--sp-3e);
    padding-bottom: var(--sp-3_5e);
    margin: 0 var(--sp-3e);
    flex-wrap: nowrap;
    & > button {
      background: var(--shade0-15) !important;
      overflow: hidden;
      &.active {
        background: hsla(var(--shade0-hsl) / 0.35) !important;
      }
      &:hover {
        background: hsla(var(--shade0-hsl) / 0.35) !important;
      }
    }
  }
`;

const Header = styled("div", React.forwardRef)`
  background: var(--shade10-75);
  display: flex;
  border-radius: calc(var(--sp-1e) + var(--sp-pxe))
    calc(var(--sp-1e) + var(--sp-pxe)) 0 0;
  flex-direction: row;
  justify-content: space-between;

  &.collapsed {
    border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  background: var(--shade9-75);
  border-radius: 0 0 calc(var(--sp-1e) + var(--sp-pxe))
    calc(var(--sp-1e) + var(--sp-pxe));
  overflow: hidden;
`;

const LogoContainer = styled("div")`
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: var(--sp-2_5e) 0 var(--sp-2_5e) var(--sp-2_5e);

  .logo {
    display: flex;
    align-items: center;
    .logo-bolt {
      svg {
        height: var(--sp-4e);
      }
    }
    .logo-wordmark {
      svg {
        height: var(--sp-2e);
        width: var(--sp-11e);
      }
    }
    svg {
      display: block;
    }
  }
`;

const Toggle = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: var(--sp-1_5e);
`;

const ToggleKey = styled("p")`
  background: var(--shade2-15);
  text-transform: capitalize;
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: var(--sp-0_5e) var(--sp-1_5e) !important;
  box-shadow: inset 0px 1px 0px rgba(227, 229, 234, 0.25);
  color: var(--shade0-50);

  &:first-child {
    margin-right: var(--sp-1e);
  }
`;

const AgentContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: var(--sp-4e);
  margin-right: var(--sp-4e);
  margin-bottom: var(--sp-3e);

  & > :nth-child(2) {
    flex: 1;
    margin-left: var(--sp-2e);
  }

  & > :nth-child(3) {
    align-items: end;
  }

  .agent-headshot {
    width: var(--sp-10e);
    height: var(--sp-10e);
    overflow: hidden;
    border-radius: calc(var(--sp-1e) + var(--sp-pxe));
    img {
      width: 100%;
      height: auto;
    }
  }

  .agent-name {
    font-size: var(--sp-3_5e);
    line-height: var(--sp-6e);
  }

  .agent-matches {
    color: var(--shade1);
  }

  .agent-winloss {
    color: var(--shade1);
  }
`;

const Column = styled("div")`
  display: flex;
  flex-direction: column;

  > &:first-child {
    margin-bottom: var(--sp-0_5e);
  }
`;

const EmptyAgentsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 0 var(--sp-3e) var(--sp-6e);

  svg {
    font-size: var(--sp-7e);
    color: var(--shade1-75);
    margin-left: var(--sp-3e);
    margin-right: var(--sp-3e);
    path {
      fill: var(--shade1-75);
    }
  }
`;

const WarningText = styled("p")`
  font-size: var(--sp-3e);
  line-height: var(--sp-5e);
  color: var(--shade1-75);
`;

const SORT_BY = {
  winRate: "winRate",
  kda: "kda",
  score: "score",
};

const TOGGLE_KEY = {
  key: "Tab",
  modifiers: {
    ctrlKey: IS_APP,
    altKey: false,
    shiftKey: false,
  },
};

const platformPath = getPlatformPath(false);

const BestAgents = ({ profileId, agentId, matchHistory, letterbox, queue }) => {
  const { t } = useTranslation();
  const {
    val: {
      content: { acts = [], agents = [] } = {},
      meta: { agents: { list: agentsMeta } = {} } = {},
      playerAgentStats,
      profiles: { [profileId]: { puuid } = {} },
    },
  } = useSnapshot(readState);
  const [collapsed, setCollapsed] = useState(false);
  const [sortBy, setSortBy] = useState(SORT_BY.winRate);
  const allAgentStats = useMemo(() => {
    const latestActId = acts.find(
      (act) => act.endedAt === null && act.episode.endedAt === null,
    )?.uuid;
    const result =
      playerAgentStats?.[puuid]?.[latestActId]?.[platformPath]?.[queue];
    return Array.isArray(result) ? result : [];
  }, [acts, playerAgentStats, puuid, queue]);
  const selectedAgentStats = useMemo(() => {
    return allAgentStats.find(
      (stats) => stats.agent.uuid.toLowerCase() === agentId?.toLowerCase(),
    );
  }, [agentId, allAgentStats]);
  const top3Agents = useMemo(() => {
    return orderBy(
      allAgentStats.map((stats) => {
        const {
          matchesWon,
          matchesLost,
          kills,
          assists,
          deaths,
          score,
          matchesPlayed,
          roundsPlayed,
          agent: { uuid },
        } = stats;
        return {
          id: uuid,
          winRate: calcRate(
            matchesWon,
            // remove ties from matchesPlayed
            matchesPlayed - (matchesPlayed - matchesLost - matchesWon),
            2,
          ),
          kda: calcRate(kills + assists, deaths, 2),
          matches: matchesPlayed,
          wins: matchesWon,
          ties: matchesPlayed - matchesLost - matchesWon,
          losses: matchesLost,
          score: calcRate(score, roundsPlayed),
        };
      }),
      sortBy,
      "desc",
    ).slice(0, 3);
  }, [allAgentStats, sortBy]);

  const modifier = getModifier(TOGGLE_KEY.modifiers);

  useKeypress(
    "Tab",
    (e) => {
      if (!IS_APP && !IS_NODE) {
        e?.preventDefault();
        setCollapsed(!collapsed);
      }
    },
    [collapsed],
  );

  useEffect(() => {
    handleMessage(EVENTS.ELECTRON_OVERLAY_HOTKEY_MSG, ({ action }) => {
      if (action === "game_hotkey_up") {
        setCollapsed(!collapsed);
      }
    });

    return () => {
      handleMessage(EVENTS.ELECTRON_OVERLAY_HOTKEY_MSG, () => {});
    };
  }, [collapsed]);

  return (
    <Container className={letterbox ? "letterbox" : ""}>
      <Header className={collapsed ? "collapsed" : ""}>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Toggle>
          {modifier ? (
            <ToggleKey className="type-form--sortcut">{modifier}</ToggleKey>
          ) : null}
          <ToggleKey className="type-form--sortcut">{TOGGLE_KEY.key}</ToggleKey>
        </Toggle>
      </Header>
      {!collapsed ? (
        <Content>
          <SelectedAgent
            agentId={agentId}
            matchHistory={matchHistory}
            wins={selectedAgentStats?.matchesWon}
            ties={
              selectedAgentStats
                ? selectedAgentStats.matchesPlayed -
                  selectedAgentStats.matchesLost -
                  selectedAgentStats.matchesWon
                : null
            }
            matches={selectedAgentStats?.matchesPlayed}
            kills={selectedAgentStats?.kills}
            deaths={selectedAgentStats?.deaths}
            assists={selectedAgentStats?.assists}
            score={selectedAgentStats?.score}
            roundsPlayed={selectedAgentStats?.roundsPlayed}
          />
          <ButtonGroup className="filters">
            <Button
              className={sortBy === SORT_BY.winRate && "active"}
              onClick={() => setSortBy(SORT_BY.winRate)}
            >
              <span>{t("val:winRate", "Win %")}</span>
            </Button>
            <Button
              className={sortBy === SORT_BY.kda && "active"}
              onClick={() => setSortBy(SORT_BY.kda)}
            >
              <span>{t("common:stats.kda", "KDA")}</span>
            </Button>
            <Button
              className={sortBy === SORT_BY.score && "active"}
              onClick={() => setSortBy(SORT_BY.score)}
            >
              <span>{t("common:stats.avgScore", "Avg. Score")}</span>
            </Button>
          </ButtonGroup>
          {top3Agents.map(
            ({ id, winRate, wins, ties, kda, score, matches }) => {
              const agentKey = agents
                .find((agentObj) => agentObj.uuid === id)
                ?.name?.toLowerCase()
                ?.replaceAll("/", "");
              const agent = agentsMeta?.find((o) => o.key === agentKey);
              const agentImg = getAgentImage(agent?.key);
              const kdaRatio = kda.toLocaleString(getLocale(), {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              });
              const avgScore = score.toLocaleString(getLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
              return (
                <AgentContainer key={id}>
                  <div className="agent-headshot">
                    <img src={agentImg} />
                  </div>
                  <Column>
                    <p className="type-caption--bold agent-name">
                      {t(`val:agents.${agentKey}`, agent?.name)}
                    </p>
                    <p className="type-overline agent-matches">
                      {matches > 1
                        ? t("lol:countMatches", "{{count}} Matches", {
                            count: matches,
                          })
                        : t("lol:countMatch", "{{count}} Match", {
                            count: matches,
                          })}
                    </p>
                  </Column>
                  <Column>
                    {sortBy === SORT_BY.winRate ? (
                      <p
                        className="type-subtitle2"
                        style={{ color: getWinRateColor(winRate) }}
                      >
                        {displayRate(winRate, 1)}
                      </p>
                    ) : sortBy === SORT_BY.kda ? (
                      <p
                        className="type-subtitle2"
                        style={{ color: kdaColor(kda) }}
                      >
                        {kdaRatio}
                      </p>
                    ) : sortBy === SORT_BY.score ? (
                      <p
                        className="type-subtitle2"
                        style={{ color: scoreColorStyle(score) }}
                      >
                        {avgScore}
                      </p>
                    ) : null}
                    <p className="type-overline agent-winloss">
                      {t(
                        "val:stats.matchRecordSmall",
                        "{{wins}}W-{{losses}}L",
                        {
                          wins: wins,
                          losses: matches - wins - ties,
                        },
                      )}
                    </p>
                  </Column>
                </AgentContainer>
              );
            },
          )}
          {top3Agents.length === 0 ? (
            <EmptyAgentsContainer>
              <Warning />
              <WarningText className="type-body2">
                {t(
                  "val:overlay.playMore",
                  "Play more matches to generate agent suggestions.",
                )}
              </WarningText>
            </EmptyAgentsContainer>
          ) : null}
        </Content>
      ) : null}
    </Container>
  );
};

export default BestAgents;

import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { winRatecolorRange } from "@/app/util.mjs";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import { AGENT_CLASS_ICONS } from "@/game-val/constants/constants-class-icons.mjs";
import {
  calcAvgScore,
  calcWinrate,
  getAgentImage,
  getAgentName,
  getOutcomeShort,
  getOutcomeShortColor,
  kdaColor,
  scoreColorStyle,
} from "@/game-val/utils.mjs";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    180deg,
    ${({ $agentKey }) =>
        $agentKey
          ? `hsla(${AGENT_COLORS[$agentKey]}, 0.25)`
          : "rgba(227, 229, 234, 0.05)"}
      0%,
    ${({ $agentKey }) =>
        $agentKey
          ? `hsl(${AGENT_COLORS[$agentKey]})`
          : "rgba(227, 229, 234, 0)"}
      100%
  );
  padding: var(--sp-2_5e) var(--sp-4e);

  .agent-container {
    height: var(--sp-10e);
  }

  .agent-details {
    margin-left: var(--sp-3e);
    justify-content: space-between;
    height: 100%;
  }

  .agent-headshot {
    width: var(--sp-10e);
    height: var(--sp-10e);
    overflow: hidden;
    border-radius: calc(var(--sp-1e) + var(--sp-pxe));
    img {
      width: 100%;
      height: auto;
    }
  }

  .agent-class {
    height: var(--sp-5e);
    width: var(--sp-5e);
    margin-right: var(--sp-1_5e);
  }

  .agent-stats {
    margin-top: var(--sp-2e);
    justify-content: space-between;
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  p.stat {
    font-weight: 700;
    font-size: var(--sp-3_5e);
  }

  p.stat-text {
    font-size: var(--sp-3e);
    color: var(--shade0-75);
  }
`;

const Outcome = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--sp-4e);
  height: var(--sp-4e);
  border-radius: var(--br);
  margin-right: var(--sp-0_5);

  --background-color: var(--shade1-hsl);
  &.win {
    --background-color: var(--turq-hsl);
  }
  &.loss {
    --background-color: var(--red-hsl);
  }
  &.tie {
    --background-color: var(--shade3-hsl);
  }
  background: hsla(var(--background-color) / 0.25);
`;

const EmptyAgentHeadshot = styled("div")`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: calc(var(--sp-1e) + var(--sp-pxe));
  background-color: var(--shade6);
`;

const SelectedAgent = ({
  agentId,
  matchHistory = [],
  wins = 0,
  ties = 0,
  matches = 0,
  kills = 0,
  deaths = 0,
  assists = 0,
  score = 0,
  roundsPlayed = 0,
}) => {
  const { t } = useTranslation();
  const {
    val: {
      content: { agents: agentsConstant = [] } = {},
      meta: { agents: { list: agents = [] } = {} } = {},
    },
  } = useSnapshot(readState);
  const { agentKey, agentName, agentClass, agentImg } = useMemo(() => {
    let agentKey, agentName, agentClass, agentImg;
    if (agentId) {
      const agentConstant = agentsConstant?.find(
        (agentObj) => agentObj.uuid.toLowerCase() === agentId.toLowerCase(),
      );
      const agent = agents?.find(
        (o) =>
          o.key === agentConstant?.name?.toLowerCase()?.replaceAll("/", ""),
      );
      if (agent) {
        agentKey = agent.key;
        agentName = agent.name;
        agentClass = agent.Class;
        agentImg = getAgentImage(agentKey);
      }
    }

    return {
      agentKey,
      agentName,
      agentClass,
      agentImg,
    };
  }, [agentId, agents, agentsConstant]);

  const winRate = calcWinrate({ wins, ties, matches });
  const kda = calcRate(kills + assists, deaths, 2);
  const kdaString = kda.toLocaleString(getLocale(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const avgScore = calcAvgScore(score, roundsPlayed);
  const avgScoreString = avgScore.toLocaleString(getLocale(), {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  const emptyStat = "-";
  const AgentClassIcon = AGENT_CLASS_ICONS[agentClass];

  return (
    <Container $agentKey={agentKey}>
      <div className="row agent-container">
        {agentImg ? (
          <div className="agent-headshot">
            <img src={agentImg} />
          </div>
        ) : (
          <EmptyAgentHeadshot size={40} />
        )}
        <div className="column agent-details">
          <div className="row">
            {AgentClassIcon ? <AgentClassIcon className="agent-class" /> : null}
            <p className="type-caption--bold agent-name">
              {agentId
                ? getAgentName(t, agentKey, agentName)
                : t("val:overlay.selectAnAgent", "Select an agent")}
            </p>
          </div>
          <div className="row">
            {matchHistory.map(({ won }, index) => {
              const outcome = won ? "win" : "loss";
              return (
                <Outcome className={outcome} key={index}>
                  <p
                    className="type-mini"
                    style={{
                      color: getOutcomeShortColor(outcome),
                    }}
                  >
                    {getOutcomeShort(t, outcome)}
                  </p>
                </Outcome>
              );
            })}
            {matchHistory.length < 5
              ? new Array(5 - matchHistory.length)
                  .fill(null)
                  .map((_, index) => {
                    const outcome = "unknown";
                    return (
                      <Outcome className={outcome} key={index}>
                        <p
                          className="type-mini"
                          style={{
                            color: getOutcomeShortColor(outcome),
                          }}
                        >
                          {getOutcomeShort(t, outcome)}
                        </p>
                      </Outcome>
                    );
                  })
              : null}
          </div>
        </div>
      </div>
      <div className="row agent-stats">
        <div className="column">
          <p
            className="stat"
            style={{
              color: matches > 0 ? winRatecolorRange(winRate) : "var(--shade2)",
            }}
          >
            {matches > 0 ? displayRate(winRate, 100) : emptyStat}
          </p>
          <p className="stat-text">{t("lol:winRate", "Win Rate")}</p>
        </div>
        <div className="column">
          <p
            className="stat"
            style={{
              color: matches > 0 ? kdaColor(kda) : "var(--shade2)",
            }}
          >
            {matches > 0 ? kdaString : emptyStat}
          </p>
          <p className="stat-text">{t("lol:kda", "KDA")}</p>
        </div>
        <div className="column">
          <p
            className="stat"
            style={{
              color: matches > 0 ? scoreColorStyle(avgScore) : "var(--shade2)",
            }}
          >
            {matches > 0 ? avgScoreString : emptyStat}
          </p>
          <p className="stat-text">{t("val:avgScore", "Avg. Score")}</p>
        </div>
      </div>
    </Container>
  );
};

export default SelectedAgent;

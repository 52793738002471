import React, { useEffect, useRef } from "react";
import { css, styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP, IS_NODE } from "@/__main__/constants.mjs";
import { updateRoute } from "@/__main__/router.mjs";
import { setFeatureFlag, setVolatileKV } from "@/app/actions.mjs";
import { appURLs } from "@/app/constants.mjs";
import {
  setupAgentSelectListeners,
  teardownAgentSelectListeners,
} from "@/game-val/actions.mjs";
import AgentSelectPlayerStats from "@/game-val/AgentSelectPlayerStats.jsx";
import BestAgents from "@/game-val/BestAgents.jsx";
import { OVERLAY_IDS } from "@/game-val/constants.mjs";
import { GAME_SYMBOL_VAL } from "@/game-val/definition-symbol.mjs";
import Info from "@/game-val/Info.jsx";
import { valAgentSelectRefs } from "@/game-val/refs.mjs";
import UnsupportedOverlayInfo from "@/game-val/UnsupportedOverlayInfo.jsx";
import { getNameTag } from "@/game-val/utils.mjs";
import { getPlatformPath } from "@/game-val/utils/console.mjs";
import AgentSelectFixture from "@/game-val/valorant-agent-select-fixture.json";
import GameDraggableUnit from "@/shared/GameDraggableUnit.jsx";
import registerOverlays from "@/util/register-overlays.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const cssAdInitialPosition = () => css`
  position: absolute;
  bottom: var(--sp-3);
  right: var(--sp-5);
`;

const Container = styled("div")`
  aspect-ratio: 16/9;
  ${({ $letterbox }) => ($letterbox ? "margin: auto" : "align-self: baseline")};
  background: ${!IS_APP
    ? `url(${appURLs.CDN}/${AgentSelectFixture.background})`
    : "initial"};
  background-size: cover;
  overflow: hidden;
  position: ${({ $letterbox }) => ($letterbox ? "relative" : "initial")};
  @media (min-aspect-ratio: 16/9) {
    height: 100%;
    width: calc(100vh * 16 / 9);
    font-size: 1.481vh;
  }
  @media (max-aspect-ratio: 16/9) {
    width: 100%;
    height: calc(100vw * 9 / 16);
    font-size: 0.834vw;
    visibility: ${({ $letterbox, $fullscreen }) =>
      !$letterbox || $fullscreen ? "hidden" : "visible"};
  }
  @media (aspect-ratio: 16/9) {
    visibility: visible;
  }
`;

const PlayerStatsContainer = styled("div")`
  position: relative;
  display: flex;
  flex-direction: column;
  @media (min-aspect-ratio: 16/9) {
    margin-top: ${({ $replication }) => {
      return $replication
        ? "calc(8.0109 * var(--sp-10e))"
        : "calc(7.8 * var(--sp-10e))";
    }};
    margin-left: ${({ $replication }) => {
      return $replication
        ? "calc(4.1 * var(--sp-10e))"
        : "calc(4.2 * var(--sp-10e))";
    }};
  }
  @media (max-aspect-ratio: 16/9) {
    width: 100%;
    margin-top: ${({ $replication, $letterbox }) => {
      if ($letterbox) {
        return $replication
          ? "calc(7.9 * var(--sp-10e))"
          : "calc(7.9 * var(--sp-10e))";
      }
      return $replication
        ? "calc(8.0109 * var(--sp-10e))"
        : "calc(7.8 * var(--sp-10e))";
    }};
    margin-left: ${({ $replication, $letterbox }) => {
      if ($letterbox) {
        return $replication
          ? "calc(4.05 * var(--sp-10e))"
          : "calc(4.2 * var(--sp-10e))";
      }
      return $replication
        ? "calc(4.53 * var(--sp-10e))"
        : "calc(4.68 * var(--sp-10e))";
    }};
  }
  @media (aspect-ratio: 16/9) {
    margin-top: ${({ $replication }) =>
      $replication
        ? "calc(8 * var(--sp-10e))"
        : "calc(7.8003 * var(--sp-10e))"};
    margin-left: ${({ $replication }) =>
      $replication
        ? "calc(4.05 * var(--sp-10e))"
        : "calc(4.2 * var(--sp-10e))"};
  }
`;

function AgentSelect() {
  const { currentPath, searchParams, state: routeState } = useRoute();
  const videoAdRef = useRef();

  useEffect(() => {
    setupAgentSelectListeners();

    // Testing purposes only
    if (!IS_APP && !IS_NODE) {
      setVolatileKV("valAgentId", AgentSelectFixture.agentSelect.agentId);
      setFeatureFlag("ads", true);

      updateRoute(currentPath, searchParams, {
        gameName: AgentSelectFixture.currentMatch.gameName,
        tagLine: AgentSelectFixture.currentMatch.tagLine,
        queue: AgentSelectFixture.currentMatch.queue,
        mode: AgentSelectFixture.currentMatch.mode,
        players: AgentSelectFixture.currentMatch.players,
      });
    }

    return () => {
      teardownAgentSelectListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const platformPath = getPlatformPath(false); // PC only
  const state = useSnapshot(readState);
  const {
    val: {
      profiles,
      playerActStats,
      playerMatchWinHistory,
      content: { acts },
    },
    settings: {
      valorant: { overlays: { isAgentSelectEnabled } = {} },
    },
    volatile: {
      valAgentId: agentId,
      valLetterbox: letterbox,
      valFullscreen: fullscreen,
    },
  } = state;
  const { players, queue, mode } = routeState;
  const latestActId = acts.find((act) => act.endedAt === null)?.uuid;

  const player = players?.find((p) => p.isMe);
  const nameTag = getNameTag(player?.gameName, player?.tagLine);
  const profile =
    profiles[nameTag] && !(profiles[nameTag] instanceof Error)
      ? profiles[nameTag]
      : null;
  const winHistoryRaw =
    playerMatchWinHistory[profile?.puuid]?.[latestActId]?.[queue];
  const winHistory =
    winHistoryRaw &&
    !(winHistoryRaw instanceof Error) &&
    Array.isArray(winHistoryRaw)
      ? winHistoryRaw
      : [];
  const playerTeamId = player?.teamId;
  const orderedPlayers = players?.filter((p) => p.teamId === playerTeamId);
  const hasData = players?.length > 0;
  const isReplication = queue === "onefa" || mode === "oneforall";
  const shouldRender = orderedPlayers && !isReplication;
  registerOverlays(
    GAME_SYMBOL_VAL,
    shouldRender && isAgentSelectEnabled ? ["agentSelect"] : [],
  );
  if (!shouldRender) return null;

  return (
    <>
      <Container $letterbox={letterbox} $fullscreen={fullscreen}>
        {isAgentSelectEnabled ? (
          <PlayerStatsContainer
            $letterbox={letterbox}
            $replication={isReplication}
          >
            {orderedPlayers.map(
              ({ gameName, tagLine, isMe, isPartyMember }, index) => {
                const nameTag = getNameTag(gameName, tagLine);
                const profile = profiles[nameTag];
                const unknownProfile = !profile || profile instanceof Error;
                const isBlitzUser =
                  !!profiles[nameTag]?.valorantProfile?.userAccountId;
                const latestPlayerActStats =
                  playerActStats[profile?.puuid]?.[latestActId]?.[platformPath];
                const actStats = !(latestPlayerActStats instanceof Error)
                  ? latestPlayerActStats?.find((s) => s.queue === queue)
                  : null;
                const winHistoryRaw =
                  playerMatchWinHistory[profile?.puuid]?.[latestActId]?.[queue];
                const winHistory =
                  winHistoryRaw &&
                  !(winHistoryRaw instanceof Error) &&
                  Array.isArray(winHistoryRaw)
                    ? winHistoryRaw
                    : [];
                if (unknownProfile) {
                  return (
                    <AgentSelectPlayerStats
                      key={nameTag}
                      index={index}
                      isReplication={isReplication}
                    />
                  );
                }
                return (
                  <AgentSelectPlayerStats
                    key={profile.puuid}
                    matchHistory={winHistory}
                    wins={actStats?.matchesWon}
                    matches={actStats?.matchesPlayed}
                    headshots={actStats?.headshots}
                    bodyshots={actStats?.bodyshots}
                    legshots={actStats?.legshots}
                    kills={actStats?.kills}
                    deaths={actStats?.deaths}
                    assists={actStats?.assists}
                    damage={actStats?.damagePerRound}
                    score={actStats?.score}
                    roundsPlayed={actStats?.roundsPlayed}
                    rank={profile.valorantProfile.latestTier}
                    index={index}
                    isRankVisible={isMe || isPartyMember || isBlitzUser}
                    isReplication={isReplication}
                  />
                );
              },
            )}
          </PlayerStatsContainer>
        ) : null}
        {isAgentSelectEnabled && hasData ? (
          <BestAgents
            agentId={agentId}
            matchHistory={winHistory}
            profileId={nameTag}
            letterbox={letterbox}
            queue={queue}
          />
        ) : null}
        {isAgentSelectEnabled && hasData ? (
          <Info overlayId={OVERLAY_IDS.valAgentSelectInfo} />
        ) : null}
      </Container>
      <UnsupportedOverlayInfo fullscreen={fullscreen} letterbox={letterbox} />
      {valAgentSelectRefs.VideoAd ? (
        <GameDraggableUnit
          game="valorant"
          overlayId={OVERLAY_IDS.valAgentSelectAd}
          initialPosition={cssAdInitialPosition()}
          draggableAreaRef={videoAdRef}
        >
          <valAgentSelectRefs.VideoAd
            overlayId={OVERLAY_IDS.valAgentSelectAd}
            ref={videoAdRef}
          />
        </GameDraggableUnit>
      ) : null}
    </>
  );
}

export function meta() {
  return {
    title: [null, "VALORANT - Agent Select"],
    description: [null, "VALORANT Agent select overlay"],
  };
}

export default AgentSelect;
